import React, { useState } from "react"
import PropTypes from "prop-types"
import Link from "components/Link"
import SocialLinks from "components/navigation/SocialLinks"
import InvisibleButton from "components/buttons/InvisibleButton"
import useFooterNavigation from "hooks/useFooterNavigation"
import theme from "styles/theme"

import { ReactComponent as MarathonLogo } from "images/footer/footerLogo.svg"
import { ReactComponent as NavPlus } from "images/navPlus.svg"
import { ReactComponent as RightArrow } from "images/footer/rightArrow.svg"

const Footer = () => {
  const { sections, legalLinks, copyright, socialLinks } = useFooterNavigation()

  return (
    <footer
      id="global-footer"
      css={{
        "--pTB": "66px",
        "--pLR": "42px",
        padding: "var(--pTB) var(--pLR)",
        backgroundColor: theme.textGray,
        [theme.tablet]: { "--pTB": "56px" },
        [theme.mobile]: { "--pTB": "45px" },
      }}
    >
      <div
        css={{
          display: "grid",
          gridTemplateColumns: "1fr 2fr",
          maxWidth: 1184,
          margin: "0 auto",
          [theme.tablet]: {
            display: "block",
            maxWidth: "none",
          },
        }}
      >
        <MarathonLogo
          css={{
            display: "block",
            width: 172,
            [theme.tablet]: {
              width: 150,
              margin: "0 auto",
            },
            [theme.mobile]: {
              width: 143,
            },
          }}
        />

        <NavLinks
          sections={sections}
          css={{ [theme.tablet]: { marginTop: "var(--pTB)" } }}
        />

        <SocialLinks
          {...socialLinks}
          css={{
            alignSelf: "end",
            [theme.tablet]: {
              margin: "48px auto var(--pTB)",
              justifyContent: "center",
            },
          }}
        />

        <LegalLinks
          links={legalLinks}
          copyright={copyright}
          css={{
            marginTop: 56,
            [theme.tablet]: {
              margin: "0 calc(-1 * var(--pLR)) calc(-1 * var(--pTB))",
            },
          }}
        />
      </div>
    </footer>
  )
}
export default Footer

const NavLinks = ({ sections, className }) => {
  const [selectedSection, setSelectedSection] = useState()

  const toggleSection = title => {
    selectedSection === title ? setSelectedSection() : setSelectedSection(title)
  }

  return (
    <div
      css={{
        display: "grid",
        gridTemplateColumns: "repeat(3, 1fr)",
        gap: 30,
        [theme.tablet]: {
          display: "block",
          maxWidth: 620,
          margin: "0 auto",
        },
      }}
      className={className}
    >
      {sections.map(({ _key, ...section }) => (
        <FooterNavSection
          key={_key}
          isOpen={selectedSection === section.title}
          toggleSectionOpen={toggleSection}
          {...section}
        />
      ))}
    </div>
  )
}

const FooterNavSection = ({
  title,
  links,
  isOpen,
  toggleSectionOpen,
  className,
}) => (
  <div
    css={{
      [theme.tablet]: {
        marginBottom: 10,
        transition: "border-color 200ms",
        border: `1px solid ${isOpen ? theme.lightOrange : theme.softGray}`,
      },
    }}
    className={className}
  >
    <InvisibleButton
      css={{
        ...theme.footerNavSection,
        paddingBottom: 6,
        color: theme.white,
        transition: "color 250ms, background-color 250ms",
        [theme.tablet]: {
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "18px 20px",
          cursor: "pointer",
          userSelect: "none",
          transition: "color 125ms, background 150ms",
          color: isOpen ? theme.textGray : theme.nearWhite,
          background: isOpen && theme.lightOrange,
          ":hover": {
            backgroundColor: isOpen ? theme.lightOrange : theme.overlayGray,
          },
        },
        [theme.mobile]: {
          padding: "14px 15px",
        },
      }}
      onClick={() => toggleSectionOpen(title)}
      aria-label={`${isOpen ? "Close" : "Open"} $`}
    >
      

      <NavPlus
        css={{
          display: "none",
          [theme.tablet]: {
            width: 15,
            height: 14,
            display: "inline",
            transition: "transform 250ms",
            transform: isOpen ? "rotate(45deg)" : "rotate(0deg)",
          },
          [theme.mobile]: {
            width: 12,
          },
        }}
        aria-hidden
      />
    </InvisibleButton>

    <div
      css={{
        [theme.tablet]: {
          maxHeight: isOpen ? 56 * links.length : 0,
          overflow: "hidden",
          transition: "max-height 250ms",
        },
      }}
    >
      {links.map(({ _key, ...rest }) => (
        <FooterNavLink key={_key} visible={isOpen} {...rest} />
      ))}
    </div>
  </div>
)

FooterNavSection.propTypes = {
  title: PropTypes.string.isRequired,
  links: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
  isOpen: PropTypes.bool,
  toggleSectionOpen: PropTypes.func.isRequired,
  className: PropTypes.string,
}

const FooterNavLink = ({ url, title, visible, className }) => (
  <Link
    href={url}
    css={{
      ...theme.footerNavLink,
      textDecoration: "none",
      display: "block",
      paddingBottom: 6,
      color: theme.cloud,
      cursor: "pointer",
      transition: "color 250ms, background-color 250ms",
      ":hover": {
        color: theme.lightOrange,
        textDecoration: "underline",
      },
      [theme.tablet]: {
        visibility: visible ? "visible" : "hidden",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "10px 20px",
        height: 56,
        borderBottom: `1px solid ${theme.softGray}`,
        ":last-child": {
          border: 0,
        },
        ":hover": {
          backgroundColor: theme.overlayGray,
          textDecoration: "none",
        },
      },
      [theme.mobile]: {
        paddingLeft: 15,
        paddingRight: 15,
      },
    }}
    className={className}
    aria-hidden={!visible}
  >
    

    <RightArrow
      css={{
        display: "none",
        [theme.tablet]: {
          display: "block",
          width: 13,
        },
      }}
      aria-hidden
    />
  </Link>
)

FooterNavLink.propTypes = {
  title: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  visible: PropTypes.bool.isRequired,
  className: PropTypes.string,
}

const LegalLinks = ({ links, copyright, className }) => (
  <div
    css={{
      color: theme.white,
      [theme.tablet]: {
        background: "#222",
        padding: "20px 42px",
        textAlign: "center",
      },
      [theme.tabletSmall]: {
        display: "flex",
        flexDirection: "column-reverse",
        padding: "48px 15px",
      },
    }}
    className={className}
  >
    <div
      css={[
        theme.copyright,
        {
          display: "inline-block",
          marginRight: 10,
          [theme.tabletSmall]: {
            marginTop: 5,
          },
        },
      ]}
    >
      {copyright}
    </div>

    <div css={{ display: "inline", [theme.tabletSmall]: { display: "block" } }}>
      {links.map(({ _key, title, url }) => (
        <Link
          key={_key}
          href={url}
          css={[
            theme.copyright,
            {
              textDecoration: "none",
              color: "inherit",
              paddingLeft: 10,
              paddingRight: 10,
              borderLeft: `1px solid ${theme.nearWhite}`,
              whiteSpace: "nowrap",
              transition: "color 250ms",
              ":hover": {
                color: theme.lightOrange,
                textDecoration: "underline",
              },
              [theme.tabletSmall]: {
                ":first-of-type": {
                  borderLeft: 0,
                },
                fontWeight: 500,
                display: "block",
                borderLeft: 0,
                padding: 0,
                marginBottom: 10,
              },
            },
          ]}
        >
          
        </Link>
      ))}
    </div>
  </div>
)

LegalLinks.propTypes = {
  links: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
  copyright: PropTypes.string.isRequired,
  className: PropTypes.string,
}

NavLinks.propTypes = {
  sections: PropTypes.array,
  className: PropTypes.string,
}
